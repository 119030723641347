<template>
  <div>
    <SubNav title="Blank" />
    <div class="container-fluid pb-1">
      <div class="mb-sm-4 mx-3 my-4 row">
        <div class="col-lg-12">
          <div class="border"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubNav from "../../components/SubNav.vue";

export default {
  name: "PreviewData",
  components: {
    SubNav,
  },
};
</script>

<style lang="scss" scoped></style>
